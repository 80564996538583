.app-container {
  text-align: center;
  padding: 20px;
}

.input-container {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 8px;
}

input {
  padding: 10px;
  width: 300px;
  margin-right: 10px;
}

button {
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
}

.download-buttons button {
  display: block;
  margin: 10px auto;
  width: 250px;
}

.dialogue-box {
  margin-top: 20px;
  padding: 20px;
  border: 2px solid #ccc;
  display: inline-block;
}
